











































































































































import _ from 'lodash';
import * as moment from 'moment';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { Inject } from 'inversify-props';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomCascader, { CascaderOptions } from '@/modules/common/components/ui-kit/custom-cascader.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '../../cars-filters.service';
import CarsService, { CarsServiceS } from '../../cars.service';
import { BRAND_AS_BROKER_ANY, BROKER_TO_BRAND, COUNTRIES_ANY } from '../../constants/car-filter-types.constant';
import { BRAND, BROKER } from '../../constants/data-source-mode.constant';
import CAR_FUEL_TYPE from '../../constants/car-fuel-type.constant';
import SendExcelReportToEmailPopup from './send-excel-repor-to-email-popup.vue';
import CarsExcelReportService, { CarsExcelReportServiceS } from '../../cars-excel-report.service';
import CarsSharedService, { CarsSharedServiceS } from '../../cars-shared.service';

@Component({
    components: {
        ModalWrapper,
        DatePicker,
        CustomCheckbox,
        CustomMultiSelect,
        CustomSelect,
        StyledSearchbar,
        SendExcelReportToEmailPopup,
        CustomCascader,
    },
})

export default class CarsExcelReportGenerator extends Vue {
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsExcelReportServiceS) protected carExcelReportService!: CarsExcelReportService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    public selectedDate: Date[] = [new Date(), new Date()];
    public currentCustomColumns = this.customColumns[0];
    private modes: Record<string, string> = { Brand: BRAND, Broker: BROKER };
    private allBrandAsBrokerValue: string = `${BRAND_AS_BROKER_ANY},All`;
    private countryQuery: string | null = null;
    private locationQuery: string | null = null;

    private currentCountryValue: string = this.carsService.storeState.settings.country || this.countryItems[0].value;
    public currentPosValue: string | null = this.carsService.storeState.settings.pos;
    public currentLokValue: number | null = this.carsService.storeState.settings.lor;
    public currentDataSource: string | null = this.carsService.storeState.settings.dataSource;
    public currentDataSourceForBrokerValue: string[] =this.initialDataSourceForBroker;
    public currentCompetitor: Record<string, any>[] = this.initialCurrentCompetitor;
    public currentLocationValues: Record<string, any>[] = [{
        name: this.carsService.storeState.settings.pickUpCityCode,
        value: this.carsService.storeState.settings.pickUpCityCode,
    }];
    public currentCategoryValue: Record<string, any>[] = this.initialCurrentCategory;
    public currentFuelTypeValues: Record<string, any>[] = this.initialCurrentFuelType;
    public currentPriceType: string = this.carsService.storeState.settings.priceShown;
    public currentTransmission: string | null = this.carsService.storeState.settings.transmission;
    public currentMileage: string | null = this.carsService.storeState.settings.mileage;
    public currentPaymentTerms: string | null = this.carsService.storeState.settings.paymentTerms;
    public currentusersEmail: string[] = [];
    public IsSendToEmailPopupOpened: boolean = false;

    public isError: boolean = false;
    public isEmpty = {
        location: false,
        competitor: false,
        pos: false,
        category: false,
        fuelType: false,
        lok: false,
        dataSource: false,
    };

    created() {
        // Force loading of vendor list, it can be not call if we don't have countries or country providers in allowedVendorsPerCountry
        // eslint-disable-next-line no-unused-expressions
        this.carsSharedService.vendorsList;
    }

    mounted() {
        this.validateRequiredField();
    }

    @Watch('currentDataSource')
    @Watch('currentPosValue')
    onCurrentDataSourceChange() {
        this.currentCompetitor = [];
    }

    @Watch('currentDataSourceForBrokerValue')
    onCurrentDataSourceForBrokerValueChange() {
        this.currentCompetitor = [];
    }

    @Watch('currentLocationValues')
    @Watch('currentCompetitor')
    @Watch('currentPosValue')
    @Watch('currentLokValue')
    @Watch('currentCategoryValue')
    @Watch('currentFuelTypeValues')
    @Watch('currentDataSource')
    onIsDisabledValueChange() {
        this.validateRequiredField();
    }

    get isBroker() {
        return this.carsSharedService.chainMode === BROKER;
    }

    get currentCountry() {
        return this.currentCountryValue;
    }

    set currentCountry(value) {
        this.currentCountryValue = value;
        this.currentLocationValues = [];
        this.currentPosValue = null;
    }

    get currentLocation() {
        return this.currentLocationValues;
    }

    set currentLocation(value) {
        this.currentLocationValues = value;
        this.currentPosValue = null;
    }

    get currentDataSourceForBroker() {
        return this.currentDataSourceForBrokerValue;
    }

    set currentDataSourceForBroker(value) {
        if (value) {
            const [currentDataSourceMode, currentBroker] = value;
            this.currentDataSourceForBrokerValue = [currentDataSourceMode, currentBroker || this.allBrandAsBrokerValue];
        }
    }

    get customColumns() {
        return [{ key: 'Rates', label: 'Rate Value Score' }];
    }

    get countryItems() {
        let clustersKeys = this.carsFiltersService.getClustersKeys();
        const initialCountry = [{ name: COUNTRIES_ANY, value: COUNTRIES_ANY }];
        if (!clustersKeys) {
            return initialCountry;
        }

        if (this.countryQuery) {
            const searchPattern = new RegExp(this.countryQuery.toLowerCase());
            clustersKeys = clustersKeys.filter(country => searchPattern.test(country.value.toLowerCase()));
        }

        return clustersKeys;
    }

    get locationList() {
        const filteredLocations = this.carsFiltersService.filterPickUpCitiesByCountry(this.currentCountry);
        if (!filteredLocations) {
            return [];
        }
        let data = filteredLocations.map(value => ({
            name: String(value.locationName),
            value: String(value.locationId),
        }));

        if (this.locationQuery) {
            const searchPattern = new RegExp(this.locationQuery.toLowerCase());
            data = data.filter(location => searchPattern.test(location.name.toLowerCase()));
        }
        return data;
    }

    get dataSourceItems() {
        const { dataSources } = this.carsSharedService.filters;
        if (!dataSources) {
            return [{ name: BRAND, value: BRAND }];
        }
        const data = dataSources.map(value => ({
            name: String(value),
            value: String(value),
        }));

        return data;
    }

    get dataSourceForBrokerModeItems(): CascaderOptions[] {
        const { allBrandsAsBroker } = this.carsService;
        const anyBrand: CascaderOptions = {
            label: `All ${this.carsSharedService.currentChainCode} brands`,
            value: this.allBrandAsBrokerValue,
        };

        return Object.keys(this.modes).map(key => {
            const mode = {
                label: key,
                value: this.modes[key],
                items: [anyBrand, ...allBrandsAsBroker.map(brand => {
                    const broker: CascaderOptions = {
                        label: this.renameBroker(brand),
                        value: brand,
                    };
                    return broker;
                })],
            };
            return mode;
        });
    }

    private renameBroker(brand: string) {
        if (this.carsSharedService && this.carsSharedService.currentChainCode) {
            const [, newBrand] = brand.split(',');
            return `${this.carsSharedService.currentChainCode} ${newBrand}`;
        }
        return '';
    }

    get dataSourceForBrokerSelectedText() {
        if (this.currentDataSourceForBrokerValue.length > 1 && this.currentDataSourceForBrokerValue[1]) {
            const [, broker] = this.currentDataSourceForBrokerValue[1].split(',');
            return `${this.currentDataSourceForBrokerValue[0]}(${broker})`;
        }
        return this.currentDataSourceForBrokerValue[0];
    }

    get currentLorValue() {
        return this.carsService.storeState.settings.lor;
    }

    get posList() {
        const { allowedLocationPerPos } = this.carsFiltersService;
        if (!allowedLocationPerPos || !this.currentLocationValues) {
            return [];
        }
        const selectedLocations = this.currentLocationValues.map(location => location.value);
        const posList = Array.from(allowedLocationPerPos).filter(([key, value]) => selectedLocations.includes(key)).map(([key, value]) => value);
        const availablePos = _.intersection(...posList);
        return Array.from(availablePos).map(value => ({
            name: String(value),
            value: String(value),
        }));
    }

    get lokList() {
        const lor = this.carsFiltersService.availableLors;
        if (!lor) {
            return null;
        }
        const data = lor.map(value => ({
            name: String(value),
            value: Number(value),
        }));
        return data;
    }

    get carCategoryList() {
        const categories = this.carsFiltersService.allCarClasses;
        if (!categories) {
            return [];
        }
        const data = categories.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get fuelTypesList() {
        const fuleTypes = CAR_FUEL_TYPE;
        if (!fuleTypes) {
            return [];
        }
        const data = fuleTypes.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get competitorsList() {
        return this.isBroker && this.currentDataSourceForBrokerValue[0] === BROKER ? this.getCompetitorsForBrokerMode() : this.getCompetitorsForBrandMode();
    }

    get initialDataSourceForBroker() {
        let brand = this.carsService.storeState.settings.currentDataSourceMode;
        let broker = this.carsService.storeState.settings.currentBrandAsBroker;

        brand = brand === BROKER_TO_BRAND ? BRAND : BROKER;
        broker = broker === BRAND_AS_BROKER_ANY ? this.allBrandAsBrokerValue : broker;

        return [brand, broker];
    }

    private getCompetitorsForBrokerMode() {
        const { dataSources } = this.carsSharedService.filters;
        if (!dataSources) {
            return [];
        }
        const data = dataSources.filter(competitor => competitor !== BRAND && competitor !== this.carsSharedService.chainName).map(value => ({
            name: String(value),
            value: String(value),
        }));

        return data.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            return 1;
        });
    }

    private getCompetitorsForBrandMode() {
        if (!this.currentDataSource || !this.currentPosValue) {
            return [];
        }
        const competitorsFilter = this.carsFiltersService.getCompetitorsFilterByDataSource(this.currentDataSource, this.currentPosValue, this.currentCountry);

        if (!this.isBroker && this.currentDataSource !== BRAND && competitorsFilter.length === 0) {
            const vendors: Record<string, string[]> = this.carsSharedService.vendorsList;
            if (!vendors[this.currentDataSource]) {
                return [];
            }
            const data = vendors[this.currentDataSource].map(value => ({
                name: String(value),
                value: String(value),
            }));
            return data.sort((a, b) => {
                if (a.value < b.value) {
                    return -1;
                }
                return 1;
            });
        }

        if (!competitorsFilter) {
            return [];
        }
        const data = competitorsFilter.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            return 1;
        });
    }

    get transmissionList() {
        const { transmissionFilter } = this.carsFiltersService;
        return transmissionFilter.map(({ value, disabled }) => ({
            name: String(value),
            value: String(value),
            disabled,
        }));
    }

    get mileageList() {
        const { mileageFilter } = this.carsFiltersService;

        return mileageFilter.map(({ value, disabled }) => ({
            name: String(value),
            value: String(value),
            disabled,
        }));
    }

    get paymentTermsList() {
        const { paymentTerms } = this.carsFiltersService;

        return paymentTerms.map(({ value, disabled }) => ({
            name: String(value),
            value: String(value),
            disabled,
        }));
    }

    get priceTypeList() {
        const priceType = ['Total', 'Net', 'Shown', 'Calculated'];
        const data = priceType.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get isDisabled() {
        return !(!!this.currentLocationValues.length && !!this.currentCompetitor.length
            && !!this.currentPosValue && !!this.currentLokValue && !!this.currentCategoryValue.length
            && !!this.currentFuelTypeValues.length);
    }

    get initialCurrentCompetitor() {
        let initialCompetitor = [];
        if (this.isBroker && this.currentDataSourceForBrokerValue[0] === BROKER) {
            initialCompetitor = this.carsService.storeState.settings.brokersCompetitors || [];
        } else {
            initialCompetitor = (this.currentDataSource ? this.carsService.storeState.settings.dataSourceCompetitors[this.currentDataSource] : []) || [];
        }
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();

        if (unselectedCompetitors && unselectedCompetitors.length) {
            initialCompetitor = initialCompetitor.filter(item => !unselectedCompetitors.includes(item));
        }

        return initialCompetitor.map(competitor => ({ name: competitor, value: competitor }));
    }

    get initialCurrentFuelType() {
        return this.carsService.storeState.settings.carFuelTypes ? this.carsService.storeState.settings.carFuelTypes.map(val => ({
            value: val,
            name: val,
        })) || [] : [];
    }

    get initialCurrentCategory() {
        return this.carsService.storeState.settings.carClasses ? this.carsService.storeState.settings.carClasses.map(val => ({
            value: val,
            name: val,
        })) || [] : [];
    }

    private validateRequiredField() {
        this.isEmpty.location = !this.currentLocationValues.length;
        this.isEmpty.competitor = !this.currentCompetitor.length;
        this.isEmpty.pos = !this.currentPosValue;
        this.isEmpty.lok = !this.currentLokValue;
        this.isEmpty.category = !this.currentCategoryValue.length;
        this.isEmpty.fuelType = !this.currentFuelTypeValues.length;
        this.isEmpty.dataSource = !this.currentDataSource;
    }

    handleCountryListChange(query: string) {
        this.countryQuery = query;
    }

    handleLocationListChange(query: string) {
        this.locationQuery = query;
    }

    handleSendToEmailButton() {
        this.IsSendToEmailPopupOpened = true;
    }

    private async generateExcelReport() {
        const dataSource = this.isBroker ? this.currentDataSourceForBrokerValue : [this.currentDataSource];
        const report: Record<string, any> = {
            required: {
                start_date: moment.utc(this.selectedDate[0]).format('YYYY-MM-DD'),
                end_date: moment.utc(this.selectedDate[1]).format('YYYY-MM-DD'),
                location_ids: this.currentLocation.map(item => item.value),
                country: this.currentCountry,
                competitors: this.currentCompetitor.map(item => item.value),
                pos: this.currentPosValue,
                lok: this.currentLokValue,
                car_categories: this.currentCategoryValue.map(item => item.value),
                fuel_type: this.currentFuelTypeValues.map(item => item.value),
                mileage: this.currentMileage,
                payment_type: this.currentPaymentTerms,
                transmission: this.currentTransmission,
                data_source: dataSource,
                price_type: this.currentPriceType,
            },
        };

        if (this.currentusersEmail.length) {
            report.optional = { users_email: this.currentusersEmail };
        }

        await this.carExcelReportService.generateExcelReport(report);
    }

    async handleSendToEmailEvent(emails: Record<string, any>) {
        this.IsSendToEmailPopupOpened = false;
        Object.keys(emails).forEach(email => {
            if (emails[email].isValid) {
                this.currentusersEmail.push(emails[email].value);
            }
        });

        if (this.currentusersEmail.length) {
            await this.generateExcelReport().catch(() => { this.isError = true; });
        }
    }

    async handleSendToUiButton() {
        await this.generateExcelReport().then(() => this.$router.go(-1)).catch(() => { this.isError = true; });
    }
}

